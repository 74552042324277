import {ecomAppDefID, membersAppDefId, Events} from './constants';
import {Experiments} from '../common/experiments/Experiments';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {Logger} from '@wix/bi-logger-ec-sf';
import {getAppManifest} from './services/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {DependantApps} from './services/dependantApps';

let appToken;
let options;
let biLogger: Logger;
let sdk: IEditorSdk;
let experiments: Experiments;
let dependantApps: DependantApps;

async function getExperiments(instance: string): Promise<Experiments> {
  const experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
    method: 'post',
    body: JSON.stringify({
      query: require('!raw-loader!../common/experiments/getConfig.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getConfig',
    }),
    headers: {
      'X-ecom-instance': instance,
      Authorization: instance,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(data => data.json())
    .then(data => {
      return (data.data.experiments || []).reduce((acc, e) => {
        acc[e.name] = e.value;
        return acc;
      }, {});
    });
  return new Experiments(experimentsResponse);
}

async function addStoresPagesAsPanel() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter(page => page.tpaApplicationId === tpaApplicationId);
  storesPages.forEach(async page => {
    const pageRef = {id: page.id, type: page.type};
    const pageData = await sdk.pages.data.get(appToken, {
      pageRef,
    });
    if (!pageData.managingAppDefId) {
      sdk.pages.data.update(appToken, {
        pageRef,
        data: {managingAppDefId: ecomAppDefID},
      });
    }
  });
}

export async function setStateForPages() {
  const applicationPages = await sdk.document.pages.getApplicationPages(appToken);
  applicationPages
    .filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID)
    .forEach(pageData => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CART ||
        pageData.tpaPageId === PageMap.THANKYOU
      ) {
        sdk.document.pages.setState(appToken, {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    });
}

export const editorScript = {
  editorReady: async (_editorSDK, _appToken, _options) => {
    options = _options;
    appToken = _appToken;
    sdk = _editorSDK;
    const instance: string = await (sdk as any).document.info.getAppInstance('token');
    const encodedInstance = instance.substring(instance.indexOf('.') + 1);
    const parsedInstance = JSON.parse(atob(encodedInstance));
    const storeId = parsedInstance.instanceId;
    const isMerchant = true;
    biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
      storeId,
      isMerchant,
      appName: 'wixstores worker',
    });
    experiments = await getExperiments(instance);
    dependantApps = new DependantApps(sdk, appToken, biLogger, options);

    if (experiments.enabled('specs.stores.NewPagesPanel')) {
      await addStoresPagesAsPanel();
      await setStateForPages();
    }

    await dependantApps.installApps();
  },
  getAppManifest: () => getAppManifest(experiments),
  onEvent: async (data, editorSDK) => {
    const {eventType, eventPayload} = data;
    const {pageRef} = eventPayload;
    switch (eventType) {
      case Events.manageStores:
        return editorSDK.editor.openDashboardPanel(appToken, {url: 'store/products', closeOtherPanels: false});
      case Events.deletePage:
        return editorSDK.pages.remove(appToken, {pageRef});
      case Events.addShopPage:
        const title = 'shop page';
        const definition = {
          data: {
            managingAppDefId: ecomAppDefID,
            tpaPageId: 'shopPage',
          },
          components: [],
        };
        const shouldAddMenuItem = true;
        return sdk.pages.add(appToken, {title, definition, shouldAddMenuItem});
    }
  },
  handleAction: ({type, payload}) => {
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case membersAppDefId: {
              return dependantApps.onMembersInstall();
            }
            default:
              return Promise.resolve();
          }
        default:
          return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
  getControllerPresets: () => Promise.resolve([]),
};
