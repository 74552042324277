import {Events} from '../constants';
import {Experiments} from '../../common/experiments/Experiments';
import {translate as t} from './translation';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';

export function getAppManifest(experiments: Experiments) {
  if (!experiments.enabled('specs.stores.NewPagesPanel')) {
    return {};
  }

  const pageActions = {
    default: [
      'Pages_Actions_Page_Rename',
      {
        title: t('Delete'),
        event: Events.deletePage,
        icon: 'deleteAction',
        type: 'page_remove',
      },
    ],
    [PageMap.PRODUCT]: [],
    [PageMap.CART]: [],
    [PageMap.THANKYOU]: [],
    hidden: [
      'Pages_Actions_Page_Rename',
      {
        title: t('Delete'),
        event: Events.deletePage,
        icon: 'deleteAction',
        type: 'page_remove',
      },
    ],
  };

  const pageSettings = {
    default: [
      {
        title: t('Info'),
        helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
        type: 'page_info',
      },
      {
        title: t('Layout'),
        type: 'layout',
      },
      {
        title: t('Permissions'),
        type: 'permissions',
      },
      {
        title: t('Seo'),
        type: 'seo',
      },
    ],
    [PageMap.CART]: [
      {
        title: t('Info'),
        helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
        type: 'page_info',
      },
      {
        title: t('Layout'),
        type: 'layout',
      },
      {
        title: t('Permissions'),
        type: 'permissions',
      },
    ],
    [PageMap.THANKYOU]: [
      {
        title: t('Info'),
        helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
        type: 'page_info',
      },
      {
        title: t('Layout'),
        type: 'layout',
      },
      {
        title: t('Permissions'),
        type: 'permissions',
      },
    ],
  };

  const applicationSettings = {
    default: {
      displayName: t('Stores Pages'),
      helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
    },
  };

  const applicationActions = {
    default: {
      defaultValues: [
        {
          title: t('Manage'),
          event: Events.manageStores,
          icon: 'settingsAction',
        },
        {
          title: t('Add shop page'),
          event: Events.addShopPage,
          icon: 'addPagePagesPanel',
          type: 'add_page',
        },
      ],
    },
  };

  const pageDescriptors = {
    default: {
      icon: 'storesPageType',
      orderIndex: 2,
    },
    [PageMap.PRODUCT]: {
      icon: 'storesPageType',
      orderIndex: 3,
    },
    [PageMap.CART]: {
      icon: 'storesPageType',
      orderIndex: 1,
    },
    [PageMap.THANKYOU]: {
      icon: 'storesPageType',
      orderIndex: 0,
    },
  };

  return {
    pages: {
      pageActions,
      pageSettings,
      applicationSettings,
      applicationActions,
      pageDescriptors,
    },
  };
}
